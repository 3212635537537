import React from 'react';
import { UiFlex, UiImg, UiLink } from '@webfox-sc/core';

const Ad: React.FC = () => {
  return (
    <UiFlex justifyContent="center" alignItems="center" height="100%">
      <UiLink href="https://fortedigital.no/de" target="_blank">
        <UiImg
          width="320px"
          height="320px"
          objectFit="contain"
          display="block"
          maxWidth="100%"
          src="/img/werbung.png"
        />
      </UiLink>
    </UiFlex>
  );
};

export default Ad;
