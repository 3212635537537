import { useAppSelector } from '../../app/hooks';

export const useCurrentMatch = (
  matchId?: number | string
): {
  match?: Match;
} => {
  const match = useAppSelector((state) => state.matches.entities[matchId || 0]);

  return {
    match,
  };
};
