import { createSlice, createEntityAdapter, createAsyncThunk, EntityState, EntityAdapter } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios, { AxiosRequestConfig } from 'axios';
import { apiUrl } from '../api';
import { transformData } from '../transformData';

type Chapters = {
  goalsBefore: GoalsBeforeChapter;
  leagueTableBefore: LeagueTableBeforeChapter;
  pointsBefore: PointsBeforeChapter;
  previousMatches: PreviousMatchesChapter;
  streaks: StreaksChapter;
};

const chapterEntityAdapters: {
  [C in keyof Chapters]: EntityAdapter<Chapters[C]>;
} = {
  goalsBefore: createEntityAdapter<GoalsBeforeChapter>(),
  leagueTableBefore: createEntityAdapter<LeagueTableBeforeChapter>(),
  pointsBefore: createEntityAdapter<PointsBeforeChapter>(),
  previousMatches: createEntityAdapter<PreviousMatchesChapter>(),
  streaks: createEntityAdapter<StreaksChapter>(),
};

type ChaptersState = {
  [C in keyof Chapters]: EntityState<Chapters[C]>;
};

const initialState = Object.keys(chapterEntityAdapters).reduce(
  (state, key) => ({
    ...state,
    [key]: chapterEntityAdapters[key].getInitialState(),
  }),
  {} as ChaptersState
);

export const requestChapter = createAsyncThunk<
  (Match & Record<string, unknown>) | undefined,
  { matchId: number | string; pathSegment: string; chapterType: keyof Chapters },
  { state: RootState }
>('requestChapter', async (arg, thunkAPI) => {
  const { auth } = thunkAPI.getState();

  const axiosConfig: AxiosRequestConfig = {
    method: 'GET',
    url: `${apiUrl}/matches/${arg.matchId}/${arg.pathSegment}`,
  };

  if (auth?.token) {
    axiosConfig.headers = {
      Authorization: `Bearer ${auth?.token}`,
    };
  }

  const { data } = await axios(axiosConfig);
  if (data?.data) {
    return transformData(data) as Match;
  }

  return undefined;
});

export const fetchPointsBeforeChapter = (matchId: number | string) =>
  requestChapter({
    matchId,
    pathSegment: 'points_before',
    chapterType: 'pointsBefore',
  });

export const fetchLeagueTableBeforeChapter = (matchId: number | string) =>
  requestChapter({
    matchId,
    pathSegment: 'league_table_before',
    chapterType: 'leagueTableBefore',
  });

export const fetchStreaksChapter = (matchId: number | string) =>
  requestChapter({
    matchId,
    pathSegment: 'streaks',
    chapterType: 'streaks',
  });

export const fetchGoalsBeforeChapter = (matchId: number | string) =>
  requestChapter({
    matchId,
    pathSegment: 'goals_before',
    chapterType: 'goalsBefore',
  });

export const fetchPreviousMatchesChapter = (matchId: number | string) =>
  requestChapter({
    matchId,
    pathSegment: 'previous_matches',
    chapterType: 'previousMatches',
  });

export const chaptersSlice = createSlice({
  name: 'matchDays',
  initialState,
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestChapter.fulfilled, (state, action) => {
      if (action.payload) {
        const chapterType = action.meta.arg.chapterType;
        (chapterEntityAdapters[chapterType] as any).upsertOne(state[chapterType], action.payload);
      }
    });
  },
});

export default chaptersSlice.reducer;
