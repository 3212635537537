import React, { useEffect } from 'react';
import { KickerSeasonsTable, KickerSeasonsTableConfigProps, KickerSeasonsTableRowProps } from '@webfox-sc/dataviz-poc';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useParams } from 'react-router-dom';
import { fetchPreviousMatchesChapter } from '../../app/slices/chaptersSlice';

// const tinyDateFormatter = new Intl.DateTimeFormat('de-DE', {
//   day: 'numeric',
//   month: '2-digit',
//   timeZone: 'Europe/Berlin',
// });

const getConfig = (chapter?: PreviousMatchesChapter): KickerSeasonsTableConfigProps | undefined => {
  if (!chapter?.home || !chapter?.away) {
    return undefined;
  }

  const matchesGroupedBySeason = (chapter.matches || []).reduce((result, match) => {
    const season = match.season?.season || '';
    if (!result[season]) {
      result[season] = [];
    }
    result[season].push(match);
    return result;
  }, {} as Record<string, typeof chapter.matches>);

  const orderedSeasons = Object.keys(matchesGroupedBySeason).sort().reverse();

  const displayedSeasons: string[] = [];
  const lastSeason: string | undefined = orderedSeasons[0];
  if (lastSeason) {
    const [lastSeasonYear1, lastSeasonYear2] = lastSeason.split('/');
    if (!!lastSeasonYear1 && !!lastSeasonYear2) {
      for (let index = 0; index < 5; index++) {
        const displayedSeason = `${parseInt(lastSeasonYear1) - index}/${parseInt(lastSeasonYear2) - index}`;
        displayedSeasons.push(displayedSeason);
      }
    }
  }

  let rows: KickerSeasonsTableRowProps[] = [];

  let homeTeamWins = 0;
  let awayTeamWins = 0;
  let drafts = 0;

  if (displayedSeasons.length) {
    if (matchesGroupedBySeason[displayedSeasons[0]].length === 1) {
      matchesGroupedBySeason[displayedSeasons[0]].unshift({
        home: matchesGroupedBySeason[displayedSeasons[0]][0].away,
      } as any);
    }

    rows = displayedSeasons.map((season) => {
      const matches = matchesGroupedBySeason[season];

      if (!matches) {
        return {
          seasonLabel: season,
          isEmpty: true,
        };
      }

      // TODO
      // const gameDate1 =
      //   matches[0].plannedKickoffTime && !matches[0].hasResults
      //     ? tinyDateFormatter.format(new Date(matches[0].plannedKickoffTime))
      //     : undefined;
      // const gameDate2 =
      //   matches[1].plannedKickoffTime && !matches[1].hasResults
      //     ? tinyDateFormatter.format(new Date(matches[1].plannedKickoffTime))
      //     : undefined;

      const firstGoalsHomeTeam =
        (chapter.home?.id === matches[0].home?.id ? matches[0].homeGoals : matches[0].awayGoals) ?? undefined;
      const firstGoalsAwayTeam =
        (chapter.away?.id === matches[0].home?.id ? matches[0].homeGoals : matches[0].awayGoals) ?? undefined;

      let firstResult: [number, number] | undefined = undefined;
      if (firstGoalsHomeTeam !== undefined && firstGoalsAwayTeam !== undefined) {
        firstResult = [firstGoalsHomeTeam, firstGoalsAwayTeam];

        if (firstGoalsHomeTeam > firstGoalsAwayTeam) {
          homeTeamWins += 1;
        } else if (firstGoalsHomeTeam < firstGoalsAwayTeam) {
          awayTeamWins += 1;
        } else {
          drafts += 1;
        }
      }

      const secondGoalsHomeTeam =
        (chapter.home?.id === matches[1].home?.id ? matches[1].homeGoals : matches[1].awayGoals) ?? undefined;
      const secondGoalsAwayTeam =
        (chapter.away?.id === matches[1].home?.id ? matches[1].homeGoals : matches[1].awayGoals) ?? undefined;

      let secondResult: [number, number] | undefined = undefined;
      if (secondGoalsHomeTeam !== undefined && secondGoalsAwayTeam !== undefined) {
        secondResult = [secondGoalsHomeTeam, secondGoalsAwayTeam];

        if (secondGoalsHomeTeam > secondGoalsAwayTeam) {
          homeTeamWins += 1;
        } else if (secondGoalsHomeTeam < secondGoalsAwayTeam) {
          awayTeamWins += 1;
        } else {
          drafts += 1;
        }
      }

      return {
        seasonLabel: season,
        firstTeamName: matches[0].home?.shortName,
        firstResult,
        firstDateOrTime: firstResult ? undefined : '–:–',
        secondTeamName: matches[1].home?.shortName,
        secondResult,
        secondDateOrTime: secondResult ? undefined : '–:–',
      };
    });
  }

  const homeTeamPoints = homeTeamWins * 3 + drafts;
  const awayTeamPoints = awayTeamWins * 3 + drafts;

  return {
    homeTeamName: chapter.home?.shortName || '',
    homeTeamImageSrc: chapter.home?.teamIconUrl || null,
    homeTeamPointsLabel: `${homeTeamPoints} ${homeTeamPoints === 1 ? 'Punkt' : 'Punkte'}`,
    homeTeamWins,
    homeTeamDrafts: drafts,
    homeTeamLosses: awayTeamWins,

    awayTeamName: chapter.away?.shortName || '',
    awayTeamImageSrc: chapter.away?.teamIconUrl || null,
    awayTeamPointsLabel: `${awayTeamPoints} ${awayTeamPoints === 1 ? 'Punkt' : 'Punkte'}`,
    awayTeamWins,
    awayTeamDrafts: drafts,
    awayTeamLosses: homeTeamWins,

    rows,
  };
};

const SeasonsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { matchId } = useParams();
  const chapter = useAppSelector((state) => state.chapters.previousMatches.entities[matchId || 0]);

  useEffect(() => {
    (async () => {
      if (matchId) {
        await dispatch(fetchPreviousMatchesChapter(matchId));
      }
    })();
  }, [dispatch, matchId]);

  const config = getConfig(chapter);

  if (!config) {
    return null;
  }

  return <KickerSeasonsTable config={config} />;
};

export default SeasonsTable;
