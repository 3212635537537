const IconKickerArrowRight = (
  <svg viewBox="0 0 6.7 12.6" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path
      d="m.98,12.6l-.86-.9,5.32-5.11s.05-.06.04-.06h-.01s-.02-.02-.02-.02c-.01-.01-.03-.02-.03-.04L0,.88.9,0l5.4,5.61c.23.24.37.56.39.9.03.37-.13.74-.42.98L.98,12.6Z"
      fill="currentColor"
    />
  </svg>
);

export default IconKickerArrowRight;
