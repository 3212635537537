import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import authReducer from './slices/authSlice';
import matchDaysReducer from './slices/matchDaysSlice';
import matchesReducer from './slices/matchesSlice';
import chaptersReducer from './slices/chaptersSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    matchDays: matchDaysReducer,
    matches: matchesReducer,
    chapters: chaptersReducer,
  },
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'development') {
      const loggerMiddleware = createLogger({
        collapsed: true,
      });
      return getDefaultMiddleware().concat(loggerMiddleware);
    }
    return getDefaultMiddleware();
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
