import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { apiToken } from '../api';

export interface AuthState {
  token?: string;
}

const initialState: AuthState = {
  token: apiToken,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
});

export const selectAuthState = (state: RootState): AuthState => state.auth;
export default authSlice.reducer;
