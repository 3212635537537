import React, { useEffect } from 'react';
import { KickerSeriesTable, KickerSeriesTableConfigProps, KickerSeriesTableRowProps } from '@webfox-sc/dataviz-poc';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useParams } from 'react-router-dom';
import { fetchStreaksChapter } from '../../app/slices/chaptersSlice';

const convertStreakArray = (streak: ('tie' | 'won' | 'lost' | 'notPlayed')[]) => {
  const series = streak.map((item) => {
    return item === 'tie' ? 'draft' : item === 'won' ? 'win' : item === 'lost' ? 'loss' : null;
  });
  return series.filter((item) => item !== null) as ('draft' | 'win' | 'loss')[];
};

const createRows = (chapter: StreaksChapter, streakType: 'notLost' | 'notWon', rankStart: number) => {
  const streaks = chapter.streaks.filter((streak) => streak.type === streakType);

  let prevGames = -1;

  const rows: KickerSeriesTableRowProps[] = streaks.map((streak, index) => {
    let teamImageSrc: string | null = null;

    const series = convertStreakArray(streak.streak || []);

    const games = series.length;
    let rank: number | null = null;
    if (games !== prevGames) {
      prevGames = games;
      rank = index + rankStart;
    }

    let isHomeTeam = false;
    if (chapter.home?.teamID === streak.team.teamID) {
      isHomeTeam = true;
      teamImageSrc = chapter.home?.teamIconUrl || null;
    }

    let isAwayTeam = false;
    if (chapter.away?.teamID === streak.team.teamID) {
      isAwayTeam = true;
      teamImageSrc = chapter.away?.teamIconUrl || null;
    }

    return {
      series,
      games,
      teamName: streak.team?.shortName,
      teamImageSrc,
      rank,
      isHomeTeam,
      isAwayTeam,
    };
  });

  return rows;
};

const getConfig = (chapter?: StreaksChapter): KickerSeriesTableConfigProps | undefined => {
  if (!chapter || !chapter.streaks) {
    return undefined;
  }

  const noLossRows = createRows(chapter, 'notLost', 1);
  const noWinRows = createRows(chapter, 'notWon', noLossRows.length + 1);

  return {
    noLossRows,
    noWinRows,
  };
};

const SeriesTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { matchId } = useParams();
  const chapter = useAppSelector((state) => state.chapters.streaks.entities[matchId || 0]);

  useEffect(() => {
    (async () => {
      if (matchId) {
        await dispatch(fetchStreaksChapter(matchId));
      }
    })();
  }, [dispatch, matchId]);

  const config = getConfig(chapter);

  if (!config) {
    return null;
  }

  return config && <KickerSeriesTable config={config} />;
};

export default SeriesTable;
