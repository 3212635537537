import React, { useEffect } from 'react';
import { KickerPointsTable, KickerPointsTableConfigProps } from '@webfox-sc/dataviz-poc';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { fetchPointsBeforeChapter } from '../../app/slices/chaptersSlice';
import { useParams } from 'react-router-dom';

const getPointsVariant = (points?: number): 'win' | 'loss' | 'draft' | 'upcoming' => {
  switch (points) {
    case 0:
      return 'loss';
    case 1:
      return 'draft';
    case 3:
      return 'win';
    default:
      return 'upcoming';
  }
};

const getConfig = (chapter?: PointsBeforeChapter): KickerPointsTableConfigProps | undefined => {
  if (!chapter || !(chapter.pairings || []).length) {
    return undefined;
  }

  const homeTeamPoints = (chapter.pairings || []).reduce(
    (total, pairing) => total + (pairing.home1?.points || 0) + (pairing.home2?.points || 0),
    0
  );
  const homeTeamPointsLabel = `${homeTeamPoints} ${homeTeamPoints === 1 ? 'Punkt' : 'Punkte'}`;

  const awayTeamPoints = (chapter.pairings || []).reduce(
    (total, pairing) => total + (pairing.away1?.points || 0) + (pairing.away2?.points || 0),
    0
  );
  const awayTeamPointsLabel = `${awayTeamPoints} ${awayTeamPoints === 1 ? 'Punkt' : 'Punkte'}`;

  return {
    homeTeamName: chapter.home?.shortName || '',
    homeTeamImageSrc: chapter.home?.teamIconUrl || null,
    homeTeamPointsLabel,
    awayTeamName: chapter.away?.shortName || '',
    awayTeamImageSrc: chapter.away?.teamIconUrl || null,
    awayTeamPointsLabel,
    ranksWithBorderBottom: [4, 5, 6, 15, 16],
    rows: (chapter.pairings || []).map((pairing, index) => ({
      rank: index + 1,
      teamName: pairing.team?.shortName || '',
      isHomeTeam: chapter.home?.shortName === pairing.team.shortName,
      isAwayTeam: chapter.away?.shortName === pairing.team.shortName,
      homeTeamFirstPointsVariant:
        chapter.home?.shortName !== pairing.team.shortName ? getPointsVariant(pairing?.home1?.points) : undefined,
      homeTeamSecondPointsVariant:
        chapter.home?.shortName !== pairing.team.shortName ? getPointsVariant(pairing?.home2?.points) : undefined,
      awayTeamFirstPointsVariant:
        chapter.away?.shortName !== pairing.team.shortName ? getPointsVariant(pairing?.away1?.points) : undefined,
      awayTeamSecondPointsVariant:
        chapter.away?.shortName !== pairing.team.shortName ? getPointsVariant(pairing?.away2?.points) : undefined,
    })),
  };
};

const PointsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { matchId } = useParams();
  const chapter = useAppSelector((state) => state.chapters.pointsBefore.entities[matchId || 0]);

  useEffect(() => {
    (async () => {
      if (matchId) {
        await dispatch(fetchPointsBeforeChapter(matchId));
      }
    })();
  }, [dispatch, matchId]);

  const config = getConfig(chapter);

  if (!config) {
    return null;
  }

  return <KickerPointsTable config={config} />;
};

export default PointsTable;
