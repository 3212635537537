import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { fetchMatchDay } from './matchDaysSlice';
import { requestChapter } from './chaptersSlice';

const matchesAdapter = createEntityAdapter<Match>();

const extractMatch = (data: Match & Record<string, unknown>): Match => ({
  id: data.id,
  away: data.away,
  matchday: data.matchday,
  plannedKickoffTime: data.plannedKickoffTime,
  awayGoals: data.awayGoals,
  homeGoals: data.homeGoals,
  home: data.home,
  sceneCount: data.sceneCount,
  sceneUrl: data.sceneUrl,
  season: data.season,
  stadiumIcon: data.stadiumIcon,
  hasResults: data.hasResults,
});

export const matchesSlice = createSlice({
  name: 'matchDays',
  initialState: matchesAdapter.getInitialState(),
  reducers: {
    //
  },
  extraReducers: (builder) => {
    builder.addCase(requestChapter.fulfilled, (state, action) => {
      if (action.payload) {
        matchesAdapter.upsertOne(state, extractMatch(action.payload));
      }
    });
    builder.addCase(fetchMatchDay.fulfilled, (state, action) => {
      if (action.payload?.matches) {
        matchesAdapter.upsertMany(state, action.payload.matches);
      }
    });
  },
});

export default matchesSlice.reducer;
