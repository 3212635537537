const IconKickerArrowLeft = (
  <svg viewBox="0 0 6.7 12.6" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path
      d="m5.72,12.6l.86-.9L1.26,6.59s-.05-.06-.04-.06h.01s.02-.02.02-.02c.01-.01.03-.02.03-.04L6.7.88,5.8,0,.4,5.6c-.23.24-.37.56-.39.9-.03.37.13.74.42.98l5.29,5.12Z"
      fill="currentColor"
    />
  </svg>
);

export default IconKickerArrowLeft;
