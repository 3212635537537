const integrateAttributes = (obj: Record<string, unknown>): Record<string, unknown> => {
  const { attributes, ...props } = obj;

  if (attributes && typeof attributes === 'object') {
    const transformedItem = {
      ...attributes,
      ...props,
    };
    for (const key in transformedItem) {
      const value = transformedItem[key];
      if (value && typeof value === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        transformedItem[key] = transformData(value as Record<string, unknown>);
      }
    }
    return transformedItem;
  }

  return obj;
};

export function transformData(obj: Record<string, unknown>): unknown {
  const { data } = obj;

  if (!data) {
    return obj;
  }

  if (Array.isArray(data)) {
    return data.map(integrateAttributes);
  }

  if (data && typeof data === 'object') {
    return integrateAttributes(data as Record<string, unknown>);
  }

  return data;
}
