import { createSlice, createEntityAdapter, createAsyncThunk, EntityState, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios, { AxiosRequestConfig } from 'axios';
import { apiUrl } from '../api';
import { transformData } from '../transformData';

const matchDaysAdapter = createEntityAdapter<MatchDay>();

type MatchDaysState = {
  matchDays: EntityState<MatchDay>;
  matchDayIdsByMatchDayNr: Record<number, number> | undefined;
  selectedMatchDay: number | undefined;
};

const initialState: MatchDaysState = {
  matchDays: matchDaysAdapter.getInitialState(),
  matchDayIdsByMatchDayNr: undefined,
  selectedMatchDay: undefined,
};

const getCurrentMatchDay = (matchDays: MatchDay[]): number => {
  if (!matchDays.length) {
    return 0;
  }

  const matchDates = matchDays.reduce((result, matchDay) => {
    return [
      ...result,
      ...(matchDay.matches || []).map((match) => ({
        matchDay: matchDay.matchDay,
        date: match.plannedKickoffTime?.split('T')[0] || '',
      })),
    ];
  }, [] as { matchDay: number; date: string }[]);

  const currentDate = new Date().toISOString().split('T')[0];

  for (const matchDate of matchDates) {
    if (currentDate <= matchDate.date) {
      return matchDate.matchDay;
    }
  }

  return matchDates[matchDates.length - 1].matchDay;
};

export const fetchMatchDay = createAsyncThunk<MatchDay | undefined, number, { state: RootState }>(
  'matchDay/fetch',
  async (id, thunkAPI) => {
    const { auth } = thunkAPI.getState();

    const axiosConfig: AxiosRequestConfig = {
      method: 'GET',
      url: `${apiUrl}/matchdays/${id}/matches`,
    };

    if (auth?.token) {
      axiosConfig.headers = {
        Authorization: `Bearer ${auth?.token}`,
      };
    }

    const { data } = await axios(axiosConfig);
    if (data?.data) {
      return transformData(data) as MatchDay;
    }

    return undefined;
  }
);

export const fetchMatchDays = createAsyncThunk<MatchDay[] | undefined, undefined, { state: RootState }>(
  'matchDays/fetch',
  async (arg, thunkAPI) => {
    const { auth } = thunkAPI.getState();

    const axiosConfig: AxiosRequestConfig = {
      method: 'GET',
      url: `${apiUrl}/seasons/2022/matchdays`,
    };

    if (auth?.token) {
      axiosConfig.headers = {
        Authorization: `Bearer ${auth?.token}`,
      };
    }

    const { data } = await axios(axiosConfig);
    if (data?.data) {
      return transformData(data) as MatchDay[];
    }

    return undefined;
  }
);

export const matchDaysSlice = createSlice({
  name: 'matchDays',
  initialState,
  reducers: {
    setSelectedMatchDay: (state, action: PayloadAction<number>) => {
      state.selectedMatchDay = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMatchDay.fulfilled, (state, action) => {
      if (action.payload) {
        matchDaysAdapter.upsertOne(state.matchDays, action.payload);
      }
    });
    builder.addCase(fetchMatchDays.fulfilled, (state, action) => {
      if (action.payload) {
        state.matchDayIdsByMatchDayNr = action.payload.reduce(
          (result, matchDay) => ({
            ...result,
            [matchDay.matchDay]: matchDay.id,
          }),
          {}
        );
        state.selectedMatchDay = getCurrentMatchDay(action.payload);
      }
    });
  },
});

export const { setSelectedMatchDay } = matchDaysSlice.actions;
export default matchDaysSlice.reducer;
