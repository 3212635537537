import React, { useEffect } from 'react';
import { KickerGoalsTable, KickerGoalsTableConfigProps } from '@webfox-sc/dataviz-poc';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useParams } from 'react-router-dom';
import { fetchGoalsBeforeChapter } from '../../app/slices/chaptersSlice';

const getConfig = (chapter?: GoalsBeforeChapter): KickerGoalsTableConfigProps | undefined => {
  if (!chapter || chapter.matchday?.matchDay === 1) {
    return undefined;
  }

  return {
    homeTeamName: chapter.home?.shortName || '',
    homeTeamImageSrc: chapter.home?.teamIconUrl || null,
    homeTeamGoalsScored: chapter.homeGoalsBefore?.goalsFor || 0,
    homeTeamGoalsConceded: chapter.homeGoalsBefore?.goalsAgainst || 0,

    awayTeamName: chapter.away?.shortName || '',
    awayTeamImageSrc: chapter.away?.teamIconUrl || null,
    awayTeamGoalsScored: chapter.awayGoalsBefore?.goalsFor || 0,
    awayTeamGoalsConceded: chapter.awayGoalsBefore?.goalsAgainst || 0,
  };
};

const GoalsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { matchId } = useParams();
  const chapter = useAppSelector((state) => state.chapters.goalsBefore.entities[matchId || 0]);

  useEffect(() => {
    (async () => {
      if (matchId) {
        await dispatch(fetchGoalsBeforeChapter(matchId));
      }
    })();
  }, [dispatch, matchId]);

  const config = getConfig(chapter);

  if (!config) {
    return null;
  }

  return <KickerGoalsTable config={config} />;
};

export default GoalsTable;
