import React, { useEffect } from 'react';
import { KickerRanksTable, KickerRanksTableConfigProps, KickerRanksTableRowProps } from '@webfox-sc/dataviz-poc';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useParams } from 'react-router-dom';
import { fetchLeagueTableBeforeChapter } from '../../app/slices/chaptersSlice';

const getSeasonRank = (
  season: LeagueTableBeforeChapterSeason | undefined,
  teamId: number | undefined
): number | null => {
  if (!season || !teamId) {
    return null;
  }

  const index = season.leagueTable.findIndex((item) => item.team.id === teamId);
  if (index === -1) {
    return null;
  }

  return index + 1;
};

const getConfig = (chapter?: LeagueTableBeforeChapter): KickerRanksTableConfigProps | undefined => {
  if (!chapter) {
    return undefined;
  }

  const numPastMatches = (chapter.matchday?.matchDay || 0) - Number(!chapter.hasResults);
  const season1LeagueTable = chapter.seasons?.[0]?.leagueTable || [];
  const season2LeagueTable = chapter.seasons?.[1]?.leagueTable || [];

  if (!season1LeagueTable.length) {
    return undefined;
  }

  const secondLeagueRows: KickerRanksTableRowProps[] = [];

  const homeTeamSeason1Rank = getSeasonRank(chapter.seasons?.[0], chapter.home?.id);
  if (homeTeamSeason1Rank === null) {
    secondLeagueRows.push({
      // TODO rank is not in data
      rank: null,
      firstTeamName: chapter.home?.shortName || '',
      firstTeamIsHome: true,
      // TODO remove or set to null
      secondTeamName: '',
    });
  }

  const awayTeamSeason1Rank = getSeasonRank(chapter.seasons?.[0], chapter.away?.id);
  if (awayTeamSeason1Rank === null) {
    secondLeagueRows.push({
      // TODO rank is not in data
      rank: null,
      firstTeamName: chapter.away?.shortName || '',
      firstTeamIsAway: true,
      // TODO remove or set to null
      secondTeamName: '',
    });
  }

  // TODO secondLeagueRows by rank asc

  return {
    firstSeasonLabel: chapter.seasons?.[0]?.season || '',
    secondSeasonLabel: chapter.seasons?.[1]?.season || '',
    gamesPlayedLabel: `Nach ${numPastMatches} ${numPastMatches === 1 ? 'Spiel' : 'Spielen'}`,
    ranksWithBorderBottom: [4, 5, 6, 15, 16],
    // TODO secondLeagueLabel is not in data
    secondLeagueLabel: secondLeagueRows.length > 0 ? '2. Bundesliga' : undefined,
    rows: season1LeagueTable.map((season1LeagueTableRow, index) => {
      const firstTeamName = season1LeagueTableRow.team?.shortName || '';
      const firstTeamId = season1LeagueTableRow.team?.teamID || '';
      const secondTeamName = season2LeagueTable[index]?.team?.shortName || '';
      const secondTeamId = season2LeagueTable[index]?.team?.teamID || '';

      return {
        rank: index + 1,
        firstTeamName: firstTeamName,
        firstTeamIsHome: firstTeamId === chapter.home?.teamID,
        firstTeamIsAway: firstTeamId === chapter.away?.teamID,
        secondTeamName: secondTeamName,
        secondTeamIsHome: secondTeamId === chapter.home?.teamID,
        secondTeamIsAway: secondTeamId === chapter.away?.teamID,
      };
    }),
    secondLeagueRows,
  };
};

const RanksTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { matchId } = useParams();
  const chapter = useAppSelector((state) => state.chapters.leagueTableBefore.entities[matchId || 0]);

  useEffect(() => {
    (async () => {
      if (matchId) {
        await dispatch(fetchLeagueTableBeforeChapter(matchId));
      }
    })();
  }, [dispatch, matchId]);

  const config = getConfig(chapter);

  if (!config) {
    return null;
  }

  return <KickerRanksTable config={config} />;
};

export default RanksTable;
