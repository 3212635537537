const monthNamesDe = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];

const getDateObject = (date: string | number | Date = new Date()) => {
  // safari fix, because the date format that we get here is not supported by Date
  // replace - with / will fix it.
  const fixedDate = date.toString().replace(/-/g, '/');

  const oDate = new Date(fixedDate);
  const day = oDate.getDate();
  const month = oDate.getMonth() + 1;
  const year = oDate.getFullYear();
  const hours = oDate.getHours();
  const minutes = oDate.getMinutes();

  return {
    day,
    month,
    year,
    hours,
    minutes,
    D: String(day),
    DD: day < 10 ? `0${String(day)}` : String(day),
    M: String(month),
    MM: month < 10 ? `0${String(month)}` : String(month),
    MMMM: monthNamesDe[month - 1],
    YYYY: String(year),
    H: String(hours),
    HH: hours < 10 ? `0${String(hours)}` : String(hours),
    mm: minutes < 10 ? `0${String(minutes)}` : String(minutes),
  };
};

/**
 * getDateFormatted
 * @return string 'DD.MM.YYYY' | undefined
 */
export const getDateFormatted = (date: string | number | Date | null | undefined): string | undefined => {
  if (!date) {
    return undefined;
  }
  const dateObject = getDateObject(date);

  return `${dateObject.DD}.${dateObject.MM}.${dateObject.YYYY}`;
};

/**
 * getDatespanFormatted
 * @return string 'DD.MM.YYYY – DD.MM.YYYY' | 'DD.MM.YYYY' | undefined
 */
export const getDatespanFormatted = (
  startDate?: string | number | Date | undefined,
  endDate?: string | number | Date | undefined
): string | undefined => {
  if (!startDate && !endDate) {
    return undefined;
  }

  const datesFormatted: string[] = [];
  if (startDate) {
    datesFormatted.push(getDateFormatted(startDate) as string);
  }
  if (endDate) {
    datesFormatted.push(getDateFormatted(endDate) as string);
  }

  if (datesFormatted[0] === datesFormatted[1]) return datesFormatted[0];
  return datesFormatted.join(' – ');
};

/**
 * getDateFormattedShort
 * @return string 'D.M.YYYY'
 */
export const getDateFormattedShort = (date: string | number | Date | undefined): string | undefined => {
  if (!date) {
    return undefined;
  }
  const dateObject = getDateObject(date);
  return `${dateObject.D}.${dateObject.M}.${dateObject.YYYY}`;
};

/**
 * getTimeFormatted
 * @return string 'HH:mm'
 */
export const getTimeFormatted = (date: string | number | Date | undefined): string | undefined => {
  if (!date) {
    return undefined;
  }
  const dateObject = getDateObject(date);
  return `${dateObject.HH}:${dateObject.mm}`;
};

/**
 * getTimeFormattedShort
 * @return string 'H:mm'
 */
export const getTimeFormattedShort = (date: string | number | Date | undefined): string | undefined => {
  if (!date) {
    return undefined;
  }
  const dateObject = getDateObject(date);
  return `${dateObject.H}:${dateObject.mm}`;
};

/**
 * getDateTimeFormatted
 * @return string 'DD.MM.YYYY HH:mm'
 */
export const getDateTimeFormatted = (date: string | number | Date | undefined): string | undefined => {
  if (!date) {
    return undefined;
  }
  return `${getDateFormatted(date)} ${getTimeFormatted(date)}`;
};

/**
 * getDateTimeFormattedShortDe
 * @return string 'DD.M.YYYY H:mm Uhr'
 */
export const getDateTimeFormattedShortDe = (date: string | number | Date | undefined): string | undefined => {
  if (!date) {
    return undefined;
  }

  const dateFormatted = getDateFormattedShort(date);
  const timeFormatted = getTimeFormattedShort(date);

  if (!dateFormatted) {
    return undefined;
  }

  if (timeFormatted === '0:00') {
    return dateFormatted;
  }

  return `${dateFormatted} ${timeFormatted} Uhr`;
};

export const dateFormatterShort = new Intl.DateTimeFormat('de-DE', {
  year: 'numeric',
  day: '2-digit',
  month: 'long',
  timeZone: 'Europe/Berlin',
});

export const dateFormatterLong = new Intl.DateTimeFormat('de-DE', {
  year: 'numeric',
  weekday: 'long',
  day: '2-digit',
  month: 'long',
  timeZone: 'Europe/Berlin',
});

export const timeFormatter = new Intl.DateTimeFormat('de-DE', {
  hour: '2-digit',
  minute: '2-digit',
  timeZone: 'Europe/Berlin',
});
