import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { KickerPageButton } from '@webfox-sc/dataviz-poc';
import { useCurrentMatch } from '../match/useCurrentMatch';
import { UiDiv, UiFlex, UiText } from '@webfox-sc/core';
import Ad from './Ad';
import PointsTable from './PointsTable';
import RanksTable from './RanksTable';
import SeriesTable from './SeriesTable';
import GoalsTable from './GoalsTable';
import SeasonsTable from './SeasonsTable';
import ChapterHeader from './ChapterHeader';
import IconKickerArrowLeft from '../../assets/icons/IconKickerArrowLeft';
import IconKickerArrowRight from '../../assets/icons/IconKickerArrowRight';

type Chapter = {
  headline?: string;
  chapterNr?: number;
  isAd?: boolean;
  component: JSX.Element;
};

const chapterLabels = ['1', '2', 'ad1', '3', '4', '5', 'ad2'] as const;

const chapters: Record<(typeof chapterLabels)[number], Chapter> = {
  '1': { headline: 'Erzielte Punkte gegen die Tabellenplätze', chapterNr: 1, component: <PointsTable /> },
  '2': { headline: 'Tabellenplätze heute und vor einem Jahr', chapterNr: 2, component: <RanksTable /> },
  ad1: { isAd: true, component: <Ad /> },
  '3': { headline: 'Aktuelle Serien', chapterNr: 3, component: <SeriesTable /> },
  '4': { headline: 'Tore und Gegentore', chapterNr: 4, component: <GoalsTable /> },
  '5': { headline: 'Begegnungen der letzten fünf Spielzeiten', chapterNr: 5, component: <SeasonsTable /> },
  ad2: { isAd: true, component: <Ad /> },
};

const Chapters: React.FC = () => {
  const { matchId, chapterId } = useParams();
  const navigate = useNavigate();

  const { match } = useCurrentMatch(matchId);

  const initialIndex = chapterLabels.findIndex((label) => label === chapterId);

  const [currentChapterIndex, setCurrentChapterIndex] = useState(initialIndex === -1 ? 0 : initialIndex);

  useEffect(() => {
    setCurrentChapterIndex(initialIndex === -1 ? 0 : initialIndex);
  }, [initialIndex]);

  const chapterLabel = chapterLabels[currentChapterIndex];
  const chapter = chapters[chapterLabel];

  const handleOnClickPref = () => {
    if (currentChapterIndex > 0) {
      navigate(`/match/${matchId}/kapitel/${chapterLabels[currentChapterIndex - 1]}`);
    } else {
      navigate('/');
    }
  };

  const handleOnClickNext = () => {
    if (currentChapterIndex < chapterLabels.length - 1) {
      navigate(`/match/${matchId}/kapitel/${chapterLabels[currentChapterIndex + 1]}`);
    } else {
      navigate('/');
    }
  };

  return (
    <UiFlex flexFlow="column" width="100%" height="100%">
      <UiDiv padding="8px 14px 0 14px">
        <ChapterHeader
          numChapters={5}
          activeChapter={chapter.chapterNr}
          infoText={`${match?.home?.shortName} – ${match?.away?.shortName}: Die visuelle Spieldatenvorschau`}
          headline={chapter.headline}
          onClickClose={() => navigate('/')}
        />
      </UiDiv>

      <UiDiv flexGrow="1" padding="0 14px 21px 14px" overflow="auto">
        {chapter.component}
      </UiDiv>

      <UiDiv padding="0 14px">
        <UiFlex
          justifyContent="flex-end"
          alignItems="flex-end"
          gap="14px"
          padding="10px 0 14px 0"
          borderTop="1px solid #B4B4B4"
        >
          {!chapter.isAd && (
            <UiText
              width="35px"
              height="21px"
              color="#B4B4B4"
              fontFamily="KickerRingsideNarr-Medium"
              fontSize="14px"
              lineHeight="21px"
              textAlign="center"
              whiteSpace="nowrap"
            >
              {chapterLabel} / 5
            </UiText>
          )}

          <UiFlex gap="7px">
            <KickerPageButton icon={IconKickerArrowLeft} onClick={handleOnClickPref} />
            <KickerPageButton icon={IconKickerArrowRight} onClick={handleOnClickNext} />
          </UiFlex>
        </UiFlex>
      </UiDiv>

      <UiFlex flex="0 0 74px" height="74px" justifyContent="center" alignItems="center" background="#DEDFE0">
        <UiText
          width="320px"
          height="50px"
          background="#4D98C9"
          color="white"
          fontFamily="KickerRingsideNarr-Book"
          fontSize="16px"
          lineHeight="50px"
          textAlign="center"
        >
          Sticky Footer 320 x 50
        </UiText>
      </UiFlex>
    </UiFlex>
  );
};

export default Chapters;
