import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    height: -moz-available;
    height: -webkit-fill-available;
  }

  body {
    background-color: #FAFAFA;
    margin: 0;
    min-height: 100vh;
    min-height: -moz-available;
    min-height: -webkit-fill-available;
  }

  #root {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

export default GlobalStyle;
