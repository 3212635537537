import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Home/Home';
import { ThemeProvider } from 'styled-components';
import { kickerTheme } from '@webfox-sc/dataviz-poc';
import Chapters from './Chapters/Chapters';
import GlobalStyle from '../theme/GlobalStyle';
import { UiDiv, UiFlex } from '@webfox-sc/core';

const App: React.FC = () => {
  return (
    <>
      <ThemeProvider theme={kickerTheme}>
        <UiFlex width="100%" height="100%" justifyContent="center" alignItems="center">
          <UiDiv
            boxSizing="border-box"
            width="100%"
            maxWidth="420px"
            height="100%"
            maxHeight="920px"
            background="white"
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="match/:matchId/kapitel/:chapterId" element={<Chapters />} />
            </Routes>
          </UiDiv>
        </UiFlex>
      </ThemeProvider>
      <GlobalStyle />
    </>
  );
};

export default App;
